import { IWithChildren } from "lib/interfaces";

interface TeaserGridProps extends IWithChildren {
  columns?: number;
  gap?: "sm" | "lg";
}

const TeaserGrid = ({ columns = 7, gap = "sm", children }: TeaserGridProps) => {
  const classes = [
    "mx-auto",
    "grid",
    gap == "sm" && "gap-4 md:gap-8",
    gap == "lg" && "gap-6 md:gap-8 lg:gap-16",
    columns === 2 && "md:grid-cols-2",
    columns === 3 && "md:grid-cols-3",
    columns === 7 && "md:grid-cols-7",
  ]
    .filter(Boolean)
    .join(" ");

  return <div className={classes}>{children}</div>;
};

export { TeaserGrid };
