import { IWithChildren } from "lib/interfaces";

interface SectionTitleProps extends IWithChildren {
  className?: string;
}

const SectionTitle = ({ className, children }: SectionTitleProps) => (
  <h2 className={`headline-3 lg:headline-2 text-center ${className}`}>
    {children}
  </h2>
);

export { SectionTitle };
