import { ButtonWrapper } from "@components/Button";
import { Image } from "@components/Image";
import { Svg } from "@components/Svg";
import { Listbox } from "@headlessui/react";
import { APITypes } from "@lib/api.types";
import { useState } from "react";

interface FigmaTrainingTeaserProps {
  data: APITypes.Collections.FigmaTraining;
}

const FigmaTrainingTeaser = ({ data }: FigmaTrainingTeaserProps) => {
  const { image, title, description, list, button } = data;

  const [selectedPlan, setSelectedPlan] = useState(list?.[0]);

  const handleOnChange = (value: string) => {
    const plan = list?.find((al) => al.value === value);
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  const newButton = {
    ...button,
    external_link: (button?.external_link ?? "/") + (selectedPlan?.value ?? ""),
  };

  return (
    <div
      className={`rounded-2xl bg-greyCold px-[40px] py-[40px] flex flex-col`}
    >
      <div className="flex-shrink-0">
        <Image
          className="rounded-t-2xl w-full max-h-[120px] md:w-auto"
          image={image.data.attributes}
          alt={image.data.attributes.name}
        />
      </div>
      <div className="pt-[16px] md:items-start text-center md:text-left flex-1">
        <div className="font-heroTitle text-[32px] md:text-[32px] font-extrabold leading-[120%]">
          {title.toUpperCase()}
        </div>
        <div className="text-[16px] font-normal leading-[150%]">
          {description}
        </div>
      </div>
      <div className="pt-[16px] grid grid-cols-1 md:grid-cols-5 gap-4">
        {list && (
          <div className="col-span-3">
            <Listbox
              className="relative"
              value={selectedPlan?.value}
              onChange={handleOnChange}
              as="div"
            >
              {({ open }) => (
                <>
                  <Listbox.Button
                    className={`flex items-center text-[16px] font-bold justify-between w-full ${
                      open ? "pb-8 md:pb-0" : "pb-0"
                    }`}
                  >
                    {selectedPlan?.title}
                    {open ? (
                      <Svg
                        className={`w-4 h-4`}
                        icon="arrow-down"
                        title="arrow-down"
                      />
                    ) : (
                      <Svg
                        className={`w-4 h-4`}
                        icon="arrow-up"
                        title="arrow-up"
                      />
                    )}
                  </Listbox.Button>
                  {!open && <hr className="h-px my-2" />}
                  <Listbox.Options className="absolute top-0 -left-10 | grid gap-2 | px-10 bg-greyCold">
                    {list.map((item) => (
                      <Listbox.Option
                        className="flex items-center | gap-2 cursor-pointer  text-[16px] font-bold"
                        value={item.value}
                        key={item.value}
                      >
                        <span>{item.title}</span>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </>
              )}
            </Listbox>
          </div>
        )}
        <div className="flex justify-center items-center col-span-2 w-full md:w-auto">
          <ButtonWrapper
            button={newButton}
            size="small"
            fill={true}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
};

export { FigmaTrainingTeaser };
